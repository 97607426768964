<template>
  <div class="p-grid">
    <div class="p-col-12"></div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

encodeURI()

onMounted(() => {
  window.location.replace(
    `dialpad://${route.query.phone_number}?launchMinimode=${route.query?.mini_mode || 1}&confirmPhone=${
      route.query?.confirm_phone || 1
    }&crmLogging=${route.query?.crm_logging || 1}&showDispositions=${route.query?.show_dispositions || 1}&customData=${
      route.query?.custom_data || ''
    }`
  )
})
</script>
